/* eslint-disable @typescript-eslint/no-duplicate-enum-values */

export enum CurrencyCode {
  EUR = 'EUR',
  AED = 'AED',
  AFN = 'AFN',
  XCD = 'XCD',
  ALL = 'ALL',
  AMD = 'AMD',
  AOA = 'AOA',
  ARS = 'ARS',
  USD = 'USD',
  AUD = 'AUD',
  AWG = 'AWG',
  AZN = 'AZN',
  BAM = 'BAM',
  BBD = 'BBD',
  BDT = 'BDT',
  BOB = 'BOB',
  BRL = 'BRL',
  XOF = 'XOF',
  BGN = 'BGN',
  BHD = 'BHD',
  BIF = 'BIF',
  BMD = 'BMD',
  BND = 'BND',
  BSD = 'BSD',
  BTN = 'BTN',
  NOK = 'NOK',
  BWP = 'BWP',
  BYR = 'BYR',
  BZD = 'BZD',
  CAD = 'CAD',
  CDF = 'CDF',
  XAF = 'XAF',
  CHF = 'CHF',
  NZD = 'NZD',
  CLF = 'CLF',
  CNY = 'CNY',
  COP = 'COP',
  CRC = 'CRC',
  CUC = 'CUC',
  CVE = 'CVE',
  ANG = 'ANG',
  CZK = 'CZK',
  DJF = 'DJF',
  DKK = 'DKK',
  DXCDM = 'XCD',
  DOP = 'DOP',
  DZD = 'DZD',
  EGP = 'EGP',
  MAD = 'MAD',
  ERN = 'ERN',
  ETB = 'ETB',
  FJD = 'FJD',
  FKP = 'FKP',
  GBP = 'GBP',
  GEL = 'GEL',
  GHS = 'GHS',
  GIP = 'GIP',
  GMD = 'GMD',
  GNF = 'GNF',
  GTQ = 'GTQ',
  GYD = 'GYD',
  HKD = 'HKD',
  HNL = 'HNL',
  HRK = 'HRK',
  HTG = 'HTG',
  HUF = 'HUF',
  IDR = 'IDR',
  ILS = 'ILS',
  INR = 'INR',
  IQD = 'IQD',
  IRR = 'IRR',
  ISK = 'ISK',
  JMD = 'JMD',
  JOD = 'JOD',
  JPY = 'JPY',
  KES = 'KES',
  KGS = 'KGS',
  KHR = 'KHR',
  KMF = 'KMF',
  KPW = 'KPW',
  KRW = 'KRW',
  KWD = 'KWD',
  KYD = 'KYD',
  KZT = 'KZT',
  LAK = 'LAK',
  LBP = 'LBP',
  LKR = 'LKR',
  LRD = 'LRD',
  LSL = 'LSL',
  LTL = 'LTL',
  LVL = 'LVL',
  LYD = 'LYD',
  MDL = 'MDL',
  MGA = 'MGA',
  MKD = 'MKD',
  MMK = 'MMK',
  MNT = 'MNT',
  MRO = 'MRO',
  MUR = 'MUR',
  MVR = 'MVR',
  MWK = 'MWK',
  MXN = 'MXN',
  MYR = 'MYR',
  MZN = 'MZN',
  NAD = 'NAD',
  XPF = 'XPF',
  NGN = 'NGN',
  NIO = 'NIO',
  NPR = 'NPR',
  OMR = 'OMR',
  PAB = 'PAB',
  PEN = 'PEN',
  PGK = 'PGK',
  PHP = 'PHP',
  PKR = 'PKR',
  PLN = 'PLN',
  PYG = 'PYG',
  QAR = 'QAR',
  RON = 'RON',
  RSD = 'RSD',
  RUB = 'RUB',
  RWF = 'RWF',
  SAR = 'SAR',
  SBD = 'SBD',
  SCR = 'SCR',
  SDG = 'SDG',
  SEK = 'SEK',
  SGD = 'SGD',
  SHP = 'SHP',
  SLL = 'SLL',
  SOS = 'SOS',
  SRD = 'SRD',
  SSP = 'SSP',
  STD = 'STD',
  SYP = 'SYP',
  SZL = 'SZL',
  THB = 'THB',
  TJS = 'TJS',
  TMT = 'TMT',
  TND = 'TND',
  TOP = 'TOP',
  TRY = 'TRY',
  TTD = 'TTD',
  TWD = 'TWD',
  TZS = 'TZS',
  UAH = 'UAH',
  UGX = 'UGX',
  UYI = 'UYI',
  UZS = 'UZS',
  VEF = 'VEF',
  VND = 'VND',
  VUV = 'VUV',
  WST = 'WST',
  YER = 'YER',
  ZAR = 'ZAR',
  ZMW = 'ZMW',
}

export const currencyCodes: Record<string, CurrencyCode> = {
  AD: CurrencyCode.EUR,
  AE: CurrencyCode.AED,
  AF: CurrencyCode.AFN,
  AG: CurrencyCode.XCD,
  AI: CurrencyCode.XCD,
  AL: CurrencyCode.ALL,
  AM: CurrencyCode.AMD,
  AO: CurrencyCode.AOA,
  AR: CurrencyCode.ARS,
  AS: CurrencyCode.USD,
  AT: CurrencyCode.EUR,
  AU: CurrencyCode.AUD,
  AW: CurrencyCode.AWG,
  AX: CurrencyCode.EUR,
  AZ: CurrencyCode.AZN,
  BA: CurrencyCode.BAM,
  BB: CurrencyCode.BBD,
  BD: CurrencyCode.BDT,
  BE: CurrencyCode.EUR,
  BF: CurrencyCode.XOF,
  BG: CurrencyCode.BGN,
  BH: CurrencyCode.BHD,
  BI: CurrencyCode.BIF,
  BJ: CurrencyCode.XOF,
  BL: CurrencyCode.EUR,
  BM: CurrencyCode.BMD,
  BN: CurrencyCode.BND,
  BO: CurrencyCode.BOB,
  BQ: CurrencyCode.USD,
  BR: CurrencyCode.BRL,
  BS: CurrencyCode.BSD,
  BT: CurrencyCode.BTN,
  BV: CurrencyCode.NOK,
  BW: CurrencyCode.BWP,
  BY: CurrencyCode.BYR,
  BZ: CurrencyCode.BZD,
  CA: CurrencyCode.CAD,
  CC: CurrencyCode.AUD,
  CD: CurrencyCode.CDF,
  CF: CurrencyCode.XAF,
  CG: CurrencyCode.XAF,
  CH: CurrencyCode.CHF,
  CI: CurrencyCode.XOF,
  CK: CurrencyCode.NZD,
  CL: CurrencyCode.CLF,
  CM: CurrencyCode.XAF,
  CN: CurrencyCode.CNY,
  CO: CurrencyCode.COP,
  CR: CurrencyCode.CRC,
  CU: CurrencyCode.CUC,
  CV: CurrencyCode.CVE,
  CW: CurrencyCode.ANG,
  CX: CurrencyCode.AUD,
  CY: CurrencyCode.EUR,
  CZ: CurrencyCode.CZK,
  DE: CurrencyCode.EUR,
  DJ: CurrencyCode.DJF,
  DK: CurrencyCode.DKK,
  DM: CurrencyCode.XCD,
  DO: CurrencyCode.DOP,
  DZ: CurrencyCode.DZD,
  EC: CurrencyCode.USD,
  EE: CurrencyCode.EUR,
  EG: CurrencyCode.EGP,
  EH: CurrencyCode.MAD,
  ER: CurrencyCode.ERN,
  ES: CurrencyCode.EUR,
  ET: CurrencyCode.ETB,
  FI: CurrencyCode.EUR,
  FJ: CurrencyCode.FJD,
  FK: CurrencyCode.FKP,
  FM: CurrencyCode.USD,
  FO: CurrencyCode.DKK,
  FR: CurrencyCode.EUR,
  GA: CurrencyCode.XAF,
  GB: CurrencyCode.GBP,
  GD: CurrencyCode.XCD,
  GE: CurrencyCode.GEL,
  GF: CurrencyCode.EUR,
  GG: CurrencyCode.GBP,
  GH: CurrencyCode.GHS,
  GI: CurrencyCode.GIP,
  GL: CurrencyCode.DKK,
  GM: CurrencyCode.GMD,
  GN: CurrencyCode.GNF,
  GP: CurrencyCode.EUR,
  GQ: CurrencyCode.XAF,
  GR: CurrencyCode.EUR,
  GS: CurrencyCode.GBP,
  GT: CurrencyCode.GTQ,
  GU: CurrencyCode.USD,
  GW: CurrencyCode.XOF,
  GY: CurrencyCode.GYD,
  HK: CurrencyCode.HKD,
  HM: CurrencyCode.AUD,
  HN: CurrencyCode.HNL,
  HR: CurrencyCode.HRK,
  HT: CurrencyCode.HTG,
  HU: CurrencyCode.HUF,
  ID: CurrencyCode.IDR,
  IE: CurrencyCode.EUR,
  IL: CurrencyCode.ILS,
  IM: CurrencyCode.GBP,
  IN: CurrencyCode.INR,
  IO: CurrencyCode.GBP,
  IQ: CurrencyCode.IQD,
  IR: CurrencyCode.IRR,
  IS: CurrencyCode.ISK,
  IT: CurrencyCode.EUR,
  JE: CurrencyCode.GBP,
  JM: CurrencyCode.JMD,
  JO: CurrencyCode.JOD,
  JP: CurrencyCode.JPY,
  KE: CurrencyCode.KES,
  KG: CurrencyCode.KGS,
  KH: CurrencyCode.KHR,
  KI: CurrencyCode.AUD,
  KM: CurrencyCode.KMF,
  KN: CurrencyCode.XCD,
  KP: CurrencyCode.KPW,
  KR: CurrencyCode.KRW,
  KW: CurrencyCode.KWD,
  KY: CurrencyCode.KYD,
  KZ: CurrencyCode.KZT,
  LA: CurrencyCode.LAK,
  LB: CurrencyCode.LBP,
  LC: CurrencyCode.XCD,
  LI: CurrencyCode.CHF,
  LK: CurrencyCode.LKR,
  LR: CurrencyCode.LRD,
  LS: CurrencyCode.LSL,
  LT: CurrencyCode.LTL,
  LU: CurrencyCode.EUR,
  LV: CurrencyCode.LVL,
  LY: CurrencyCode.LYD,
  MA: CurrencyCode.MAD,
  MC: CurrencyCode.EUR,
  MD: CurrencyCode.MDL,
  ME: CurrencyCode.EUR,
  MF: CurrencyCode.EUR,
  MG: CurrencyCode.MGA,
  MH: CurrencyCode.USD,
  MK: CurrencyCode.MKD,
  ML: CurrencyCode.XOF,
  MM: CurrencyCode.MMK,
  MN: CurrencyCode.MNT,
  MO: CurrencyCode.HKD,
  MP: CurrencyCode.USD,
  MQ: CurrencyCode.EUR,
  MR: CurrencyCode.MRO,
  MS: CurrencyCode.XCD,
  MT: CurrencyCode.EUR,
  MU: CurrencyCode.MUR,
  MV: CurrencyCode.MVR,
  MW: CurrencyCode.MWK,
  MX: CurrencyCode.MXN,
  MY: CurrencyCode.MYR,
  MZ: CurrencyCode.MZN,
  NA: CurrencyCode.NAD,
  NC: CurrencyCode.XPF,
  NE: CurrencyCode.XOF,
  NF: CurrencyCode.AUD,
  NG: CurrencyCode.NGN,
  NI: CurrencyCode.NIO,
  NL: CurrencyCode.EUR,
  NO: CurrencyCode.NOK,
  NP: CurrencyCode.NPR,
  NR: CurrencyCode.AUD,
  NU: CurrencyCode.NZD,
  NZ: CurrencyCode.NZD,
  OM: CurrencyCode.OMR,
  PA: CurrencyCode.PAB,
  PE: CurrencyCode.PEN,
  PF: CurrencyCode.XPF,
  PG: CurrencyCode.PGK,
  PH: CurrencyCode.PHP,
  PK: CurrencyCode.PKR,
  PL: CurrencyCode.PLN,
  PM: CurrencyCode.CAD,
  PN: CurrencyCode.NZD,
  PR: CurrencyCode.USD,
  PS: CurrencyCode.EGP,
  PT: CurrencyCode.EUR,
  PW: CurrencyCode.USD,
  PY: CurrencyCode.PYG,
  QA: CurrencyCode.QAR,
  RE: CurrencyCode.EUR,
  RO: CurrencyCode.RON,
  RS: CurrencyCode.RSD,
  RU: CurrencyCode.RUB,
  RW: CurrencyCode.RWF,
  SA: CurrencyCode.SAR,
  SB: CurrencyCode.SBD,
  SC: CurrencyCode.SCR,
  SD: CurrencyCode.SDG,
  SE: CurrencyCode.SEK,
  SG: CurrencyCode.SGD,
  SH: CurrencyCode.SHP,
  SI: CurrencyCode.EUR,
  SJ: CurrencyCode.NOK,
  SK: CurrencyCode.EUR,
  SL: CurrencyCode.SLL,
  SM: CurrencyCode.EUR,
  SN: CurrencyCode.XOF,
  SO: CurrencyCode.SOS,
  SR: CurrencyCode.SRD,
  SS: CurrencyCode.SSP,
  ST: CurrencyCode.STD,
  SV: CurrencyCode.USD,
  SX: CurrencyCode.ANG,
  SY: CurrencyCode.SYP,
  SZ: CurrencyCode.SZL,
  TC: CurrencyCode.USD,
  TD: CurrencyCode.XAF,
  TF: CurrencyCode.EUR,
  TG: CurrencyCode.XOF,
  TH: CurrencyCode.THB,
  TJ: CurrencyCode.TJS,
  TK: CurrencyCode.NZD,
  TL: CurrencyCode.USD,
  TM: CurrencyCode.TMT,
  TN: CurrencyCode.TND,
  TO: CurrencyCode.TOP,
  TR: CurrencyCode.TRY,
  TT: CurrencyCode.TTD,
  TV: CurrencyCode.AUD,
  TW: CurrencyCode.TWD,
  TZ: CurrencyCode.TZS,
  UA: CurrencyCode.UAH,
  UG: CurrencyCode.UGX,
  UM: CurrencyCode.USD,
  US: CurrencyCode.USD,
  UY: CurrencyCode.UYI,
  UZ: CurrencyCode.UZS,
  VA: CurrencyCode.EUR,
  VC: CurrencyCode.XCD,
  VE: CurrencyCode.VEF,
  VG: CurrencyCode.USD,
  VI: CurrencyCode.USD,
  VN: CurrencyCode.VND,
  VU: CurrencyCode.VUV,
  WF: CurrencyCode.XPF,
  WS: CurrencyCode.WST,
  YE: CurrencyCode.YER,
  YT: CurrencyCode.EUR,
  ZA: CurrencyCode.ZAR,
  ZM: CurrencyCode.ZMW,
  ZW: CurrencyCode.USD,
};
