import { usePathname, useSearchParams } from 'next/navigation';

import { getRouteView } from './referers';

export function useCurrentRoute() {
  const path = usePathname();
  const searchParams = useSearchParams();

  const combinedPath = searchParams?.toString()
    ? `${path}?${searchParams}`
    : path;

  return getRouteView(combinedPath);
}
