'use client';

import React, { useEffect, useRef } from 'react';
import { useParams, usePathname, useSearchParams } from 'next/navigation';

type Referer = string | null;

export const RefererContext = React.createContext<Referer>(null);

type Props = {
  children?: React.ReactNode;
  referer?: string;
};

function RefererProvider({ referer, children }: Props) {
  const pathname = usePathname();
  const params = useParams();
  const searchParams = useSearchParams();
  const ref = useRef<string>(referer || '');

  useEffect(() => {
    const formattedSearchParams = searchParams.size
      ? `?${searchParams.toString()}`
      : '';
    ref.current = `${window.location.origin}${pathname}${formattedSearchParams}`;
  }, [pathname, params, searchParams]);

  return (
    <RefererContext.Provider value={ref.current || ''}>
      {children}
    </RefererContext.Provider>
  );
}

export { RefererProvider };
