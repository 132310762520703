import { StatusType, datadogLogs } from '@datadog/browser-logs';
import pino, { Logger as PinoLogger } from 'pino';

import { IGNORED_CLIENT_ERROR_MESSAGES } from './constants';
import { phraseContainsKeyword } from './filterLog';

import { isServerCheck } from '@/modules/env/isServerCheck';
import { isFetchError } from '@/modules/http/helpers';

let isClientSideLoggerInited = false;
let serverSideLogger: PinoLogger;

type LogContext = Record<string, unknown>;
type LogFunction = (
  message: string,
  context?: LogContext,
  error?: Error
) => void;

export interface ICombinedLogger {
  debug: LogFunction;
  info: LogFunction;
  warn: LogFunction;
  error: LogFunction;
}

function initClientSideLogger() {
  const envType =
    process.env.NEXT_PUBLIC_DEPLOY_ENV === 'production'
      ? 'production-production'
      : 'staging-staging';
  datadogLogs.init({
    clientToken: process.env.NEXT_PUBLIC_DD_BROWSER_LOGS_CLIENT_TOKEN!,
    site: process.env.NEXT_PUBLIC_DD_BROWSER_LOGS_SITE!,
    forwardErrorsToLogs: false,
    sessionSampleRate:
      process.env.NEXT_PUBLIC_DEPLOY_ENV === 'production' ? 10 : 100,
    env: envType,
    service: 'web-default-website-server',
    version: process.env.NEXT_PUBLIC_APP_VERSION,
    beforeSend: (event) => {
      if (
        phraseContainsKeyword(
          IGNORED_CLIENT_ERROR_MESSAGES,
          `${event.message} ${event.error?.message || ''}`
        )
      ) {
        return false;
      }

      return true;
    },
  });
  const loggerLevel =
    (process.env.NEXT_PUBLIC_LOG_LEVEL as StatusType) || 'info';
  datadogLogs.logger?.setLevel(loggerLevel);
}

function initServerSideLogger(): PinoLogger {
  return pino({
    level: process.env.LOG_LEVEL || 'info',
    enabled: process.env.NODE_ENV !== 'test',
    redact: {
      paths: [
        '*.headers["x-authorization-jwt"]',
        '*.headers.authorization',
        '*.headers.Authorization',
        '*.access_token',
      ],
    },
    formatters: {
      level(label) {
        return { status: label.toUpperCase() };
      },
    },
  });
}

function getServerLoggerFunctions(pinoObject: PinoLogger): ICombinedLogger {
  const debug = pinoObject.debug.bind(pinoObject);
  const info = pinoObject.info.bind(pinoObject);
  const warn = pinoObject.warn.bind(pinoObject);
  const error = pinoObject.error.bind(pinoObject);

  return {
    debug: (message, context) =>
      context ? debug(context, message) : debug(message),
    info: (message, context) =>
      context ? info(context, message) : info(message),
    warn: (message, context) =>
      context ? warn(context, message) : warn(message),
    error: (message, context, _) =>
      context ? error(context, message) : error(message),
  };
}

export function getLogger(): ICombinedLogger {
  const server = isServerCheck();

  if (server && !serverSideLogger) {
    serverSideLogger = initServerSideLogger();
  }

  if (!server && !isClientSideLoggerInited) {
    initClientSideLogger();
    isClientSideLoggerInited = true;
  }

  if (server) {
    return getServerLoggerFunctions(serverSideLogger);
  }

  return datadogLogs.logger;
}

export function getUsefulErrorContents(
  error: Error,
  errorLocation: string
): LogContext {
  if (isFetchError(error)) {
    return {
      code: error.code,
      message: JSON.stringify(error.response?.data) || 'Unknown error occurred',
      url: error.response?.url,
      errorLocation,
    };
  }
  return {
    name: error.name,
    message: error.message,
    errorLocation,
  } as unknown as LogContext;
}
