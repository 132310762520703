import React from 'react';
import { Text } from '@depop/web-ui-kit/Typography/Text';

import { ButtonMinimal } from '@/components/ButtonMinimal';
import { ExtendedLink } from '@/components/ExtendedLink';

type LinkProp = {
  href: string;
  as?: string;
};

/* @johnf 09-11-2023: This is a quick way to create types which can have one of two properties.
 * If we refactor this component we may want to look at cleaner ways of achieving this. */
type TextMenuItemBase = {
  key: string;
  translation: string;
  element?: never;
  disabled?: boolean;
};

type ElementMenuItemBase = {
  key: string;
  translation?: never;
  element: React.ReactNode;
  disabled?: boolean;
};

export interface TextMenuItemLink extends TextMenuItemBase {
  link: LinkProp;
}

export interface TextMenuItemClick extends TextMenuItemBase {
  onClick: ((id: string, key: string) => void) | (() => void);
}

export interface ElementMenuItemLink extends ElementMenuItemBase {
  link: LinkProp;
}

export interface ElementMenuItemClick extends ElementMenuItemBase {
  onClick: ((id: string, key: string) => void) | (() => void);
}

type ActionMenuProps = {
  closeActionMenu: () => void;
  handleClick: (handler: () => void, key: string) => void;
  menuItems: Array<
    | TextMenuItemLink
    | TextMenuItemClick
    | ElementMenuItemLink
    | ElementMenuItemClick
  >;
};

function ActionMenuItems({
  menuItems,
  closeActionMenu,
  handleClick,
}: ActionMenuProps) {
  function handleBlur(index: number, size: number) {
    const itemPos = index + 1;
    if (itemPos === size) {
      closeActionMenu();
    }
  }

  return (
    <>
      {menuItems.map((item, index, arr) =>
        'link' in item ? (
          <ExtendedLink
            key={item.key}
            href={item.link.href}
            as={item.link.as}
            passHref
            legacyBehavior
          >
            <Text
              as="a"
              href="passRef"
              tabIndex={0}
              role="menuitem"
              onClick={() => closeActionMenu()}
              data-testid={`actionMenu__${item.key}Button`}
              onBlur={() => handleBlur(index, arr.length)}
            >
              {item.element || item.translation}
            </Text>
          </ExtendedLink>
        ) : (
          <ButtonMinimal
            key={item.key}
            role="menuitem"
            tabIndex={0}
            data-testid={`actionMenu__${item.key}Button`}
            onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();
              handleClick(item.onClick as () => void, item.key);
            }}
            onBlur={() => handleBlur(index, arr.length)}
            disabled={item?.disabled}
          >
            <>{item.element || item.translation}</>
          </ButtonMinimal>
        )
      )}
    </>
  );
}

export { ActionMenuItems };
