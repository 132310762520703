import { useContext } from 'react';

import { getReferer } from './referers';
import { RefererContext } from './RefererProvider';
import { EventTypes } from './constants';
import { ActivityTrackerClient } from './ActivityTrackerClient';
import { useCurrentRoute } from './useCurrentRoute';

type Params = {
  userId?: number;
  overrides?: Partial<{
    transitionFrom?: string;
  }>;
};

export function useActivityTracker({ userId, overrides = {} }: Params = {}) {
  const refererUrl = useContext(RefererContext);
  const currentRoute = useCurrentRoute();

  const activityTrackerClient = ActivityTrackerClient.getInstance({ userId });

  function sendActivityTrackerEvent(
    event: EventTypes,
    params?: Record<string, unknown>,
    options?: {
      baseEventOverrides?: Record<string, unknown>;
      handleRequestFailure?: (eventData: Record<string, unknown>) => void;
    }
  ) {
    function getTransitionFrom() {
      const isViewEvent = event.toUpperCase().endsWith('VIEW');
      const previousRoute = getReferer(refererUrl);

      if (overrides.transitionFrom) {
        return overrides.transitionFrom;
      }
      if (isViewEvent) {
        return previousRoute;
      }
      return currentRoute;
    }

    return activityTrackerClient.sendActivityTrackerEvent({
      event,
      params,
      options: options?.handleRequestFailure
        ? { handleRequestFailure: options.handleRequestFailure }
        : undefined,
      baseEventOverrides: options?.baseEventOverrides,
      transitionFrom: getTransitionFrom(),
    });
  }

  return {
    sendActivityTrackerEvent,
    initialiseActivityTrackerSession:
      activityTrackerClient.initialiseActivityTrackerSession,
    reinitialiseATSession: activityTrackerClient.reinitialiseATSession,
    activityTrackerClient,
  };
}
