import React, { useState, useRef, useEffect } from 'react';
import { useTranslations } from 'next-intl';
import { useClickAway } from 'react-use';
import debounce from 'lodash/debounce';
import clsx from 'clsx';

import styles from './styles.module.css';
import {
  ActionMenuItems,
  TextMenuItemClick,
  TextMenuItemLink,
  ElementMenuItemClick,
  ElementMenuItemLink,
} from './ActionMenuItems';

import { INITIAL_PLACEMENT, getElementPlacement } from '@/modules/dom/helpers';
import { ButtonMinimal } from '@/components/ButtonMinimal';
import { useUserAgent } from '@/modules/device/useUserAgent';

type ActionMenuProps = {
  menuItemType?: 'block' | 'minimal';
  desktopOnly?: boolean;
  actionMenuId: string;
  trigger: (args: {
    toggleActionMenu: () => void;
    showActionMenu: boolean;
  }) => React.ReactNode;
  menuItems: Array<
    | TextMenuItemClick
    | TextMenuItemLink
    | ElementMenuItemClick
    | ElementMenuItemLink
  >;
  elementRef: React.RefObject<HTMLElement | null>;
  scrollRef?: React.RefObject<HTMLElement | null>;
  className?: string;
};

const SCROLL_DEBOUNCE_MS = 16;

export function ActionMenu({
  desktopOnly = false,
  actionMenuId,
  menuItemType = 'minimal',
  trigger,
  menuItems,
  elementRef,
  scrollRef,
  className,
}: ActionMenuProps) {
  const actionMenuRef = useRef(null);
  const clickAwayRef = useRef(null);
  const [showActionMenu, setShowActionMenu] = useState(false);
  const [placement, setPlacement] = useState(INITIAL_PLACEMENT);
  const { isMobileDevice } = useUserAgent();
  const t = useTranslations('common');

  const showMobileActionMenu = !desktopOnly && isMobileDevice && showActionMenu;
  const showDesktopActionMenu =
    (!isMobileDevice || desktopOnly) && showActionMenu;

  function openActionMenu(e?: Event) {
    e?.preventDefault();
    e?.stopPropagation();
    setShowActionMenu(true);
  }

  function closeActionMenu() {
    setShowActionMenu(false);
  }

  function handleClick(
    handleActionItemClick: (id: string, key: string) => void,
    key: string
  ) {
    handleActionItemClick(actionMenuId, key);
    closeActionMenu();
  }

  function setUpdatedPlacement() {
    const updatedPlacement = getElementPlacement(
      elementRef,
      actionMenuRef,
      'bottomLeft'
    );
    setPlacement(updatedPlacement);
  }

  useClickAway(clickAwayRef, closeActionMenu);

  useEffect(() => {
    function handleKeydown(e: KeyboardEvent) {
      // If the enter key is pressed then open the action menu
      if (e.code === '13' && !showActionMenu) {
        e.preventDefault();
        openActionMenu();
      }
      if (e.code === '27' && showActionMenu) {
        e.preventDefault();
        closeActionMenu();
      }
    }

    if (showActionMenu) {
      setUpdatedPlacement();
    } else {
      setPlacement(INITIAL_PLACEMENT);
    }

    if (elementRef && elementRef.current) {
      elementRef.current.addEventListener('keydown', handleKeydown);
    }
    return () => {
      if (elementRef && elementRef.current) {
        elementRef.current.removeEventListener('keydown', handleKeydown);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showActionMenu, elementRef]);

  useEffect(() => {
    const handleScroll = debounce(() => {
      if (showActionMenu && document.activeElement) {
        (document.activeElement as HTMLElement).blur();
        setShowActionMenu(false);
      }
    }, SCROLL_DEBOUNCE_MS);

    const handleResize = debounce(() => {
      if (showActionMenu && document.activeElement) {
        setUpdatedPlacement();
      }
    }, SCROLL_DEBOUNCE_MS);

    if (scrollRef && scrollRef.current) {
      scrollRef.current.addEventListener('scroll', handleScroll);
    }

    window.addEventListener('resize', handleResize);

    return () => {
      if (scrollRef && scrollRef.current) {
        scrollRef.current.removeEventListener('scroll', handleScroll);
      }
      window.removeEventListener('resize', handleResize);
    };
  }, [scrollRef, showActionMenu]);

  return (
    <div className={className}>
      {trigger({
        toggleActionMenu: openActionMenu,
        showActionMenu,
      })}
      {showDesktopActionMenu && (
        <div ref={clickAwayRef}>
          <div
            className={clsx(styles.wrapper, styles.actionMenuItemStyle, {
              [styles.actionMenuItemStyleMinimal]: menuItemType === 'minimal',
              [styles.actionMenuItemStyleBlock]: menuItemType === 'block',
            })}
            style={{ left: placement.x + 'px', top: placement.y + 'px' }}
            id={actionMenuId}
            ref={actionMenuRef}
            data-testid="actionMenu__items"
            role="menu"
          >
            <ActionMenuItems
              menuItems={menuItems}
              closeActionMenu={closeActionMenu}
              handleClick={handleClick}
            />
          </div>
        </div>
      )}
      {showMobileActionMenu && (
        <div className={styles.mobileOverlay}>
          <div ref={clickAwayRef}>
            <div
              className={clsx(
                styles.mobileWrapper,
                styles.actionMenuItemStyle,
                {
                  [styles.actionMenuItemStyleMinimal]:
                    menuItemType === 'minimal',
                  [styles.actionMenuItemStyleBlock]: menuItemType === 'block',
                }
              )}
            >
              <ActionMenuItems
                menuItems={menuItems}
                closeActionMenu={closeActionMenu}
                handleClick={handleClick}
              />
              <ButtonMinimal onClick={() => closeActionMenu()}>
                {t('Cancel')}
              </ButtonMinimal>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
