import set from 'lodash/set';
import enGB from 'date-fns/locale/en-GB';
import enUS from 'date-fns/locale/en-US';
import enIE from 'date-fns/locale/en-IE';
import enNZ from 'date-fns/locale/en-NZ';
import enCA from 'date-fns/locale/en-CA';
import it from 'date-fns/locale/it';
import fr from 'date-fns/locale/fr';
import de from 'date-fns/locale/de';
import { Locale as dateLocale } from 'date-fns';

import { languages, Language, Locale } from '@/modules/language/constants';

export type TranslationStrings = {
  [key: string]: string | TranslationStrings;
};

export function getCurrentLanguageByLocale(
  locale: Locale,
  defaultLocale: Locale
) {
  const language =
    languages.find((lang) => lang.locale === locale) ||
    languages.find((lang) => lang.locale === defaultLocale);
  return language?.value ? Language[language.value as Language] : Language.en;
}

export function getSupportedLanguages() {
  return languages.filter((lang) => lang.display);
}

/**
 *
 * @param language Language
 * @param location string
 * @returns locale for strings
 * @description we use this to derive our full locale. We will no longer
 * just have en for english, but en-US, en-GB, en-AU, etc. This allows us
 * to translate some strings for the different regions. We can scale this out
 * as we expand in the future. And we _will_ expand, won't we?
 * House of Brands 4eva!!!
 */
export function getLocaleByLanguageAndLocation(
  language: Language,
  location: string
) {
  const langLocale = `${language}-${language.toUpperCase()}`;
  const localLocale = `${language}-${location.toUpperCase()}`;
  return (
    languages.find(
      (lang) => lang.locale === langLocale || lang.locale === localLocale
    )?.locale || Locale.ENUS
  );
}

export function fixNamespacing(obj: TranslationStrings) {
  return Object.entries(obj).reduce(
    (acc, [key, value]) => set(acc, key, value),
    {}
  );
}

export function getLocale(
  location: string,
  language: string
): [string, dateLocale] {
  switch (language) {
    case 'en':
      if (location === 'ie') {
        return ['enIE', enIE];
      }
      if (location === 'nz') {
        return ['enNZ', enNZ];
      }
      if (location === 'ca') {
        return ['enCA', enCA];
      }
      if (location === 'us') {
        return ['enUS', enUS];
      }
      return ['enGB', enGB];
    case 'fr':
      return ['fr', fr];
    case 'de':
      return ['de', de];
    case 'it':
      return ['it', it];
    default:
      return ['enGB', enGB];
  }
}
