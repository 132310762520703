import React from 'react';

import { ELEMENT_IDS } from '../../constants/elementIds';

export function getElementHeight(elementId: string) {
  const width = document.getElementById(elementId)?.clientWidth;
  const height = document.getElementById(elementId)?.clientHeight;

  if (width && height && height > 0) {
    return height;
  }

  return 0;
}

export function calculateIntersectionObserverRootMargin() {
  const headerHeight = getElementHeight(ELEMENT_IDS.header) * -1;

  return `${headerHeight}px 0px 0px 0px`;
}

export const INITIAL_PLACEMENT = {
  x: 0,
  y: 0,
  tooltipWidth: 0,
};

export function getElementPlacement(
  elementRef: React.RefObject<HTMLElement | null>,
  componentRef: React.RefObject<HTMLElement | null>,
  position = 'bottom',
  gap = 8
) {
  if (
    elementRef &&
    elementRef.current &&
    componentRef &&
    componentRef.current
  ) {
    const {
      x: elementX,
      y: elementY,
      width: elementWidth,
      height: elementHeight,
      right: elementRight,
      left: elementLeft,
    } = elementRef.current.getBoundingClientRect();
    const { width: tooltipWidth, height: tooltipHeight } =
      componentRef.current.getBoundingClientRect();

    let x = elementX;
    let y = elementY;

    const isCloseBrowserEdgeRight =
      window.innerWidth - elementRight < tooltipWidth;
    const isCloseBrowserEdgeLeft = elementLeft < tooltipWidth;

    switch (position) {
      case 'top': {
        return {
          tooltipWidth,
          x: (x += elementWidth / 2),
          y: y - tooltipHeight - gap,
        };
      }
      case 'right': {
        if (isCloseBrowserEdgeRight) {
          //if element is too close to the right of the browser display on left
          return {
            tooltipWidth,
            x: (x -= tooltipWidth + gap),
            y: (y += (elementHeight - tooltipHeight) / 2),
          };
        }
        return {
          tooltipWidth,
          x: (x += elementWidth + gap),
          y: (y += (elementHeight - tooltipHeight) / 2),
        };
      }
      case 'bottom': {
        return {
          tooltipWidth,
          x: (x += elementWidth / 2),
          y: (y += elementHeight + gap),
        };
      }
      case 'bottomRight': {
        //bottom right of the parent trigger
        if (isCloseBrowserEdgeLeft) {
          //if element is too close to the left of the browser display on bottom right
          return {
            tooltipWidth,
            x,
            y: (y += elementHeight + gap),
          };
        }
        return {
          tooltipWidth,
          x: (x -= tooltipWidth - elementWidth),
          y: (y += elementHeight + gap),
        };
      }
      case 'bottomLeft': {
        //bottom left of the parent trigger
        if (isCloseBrowserEdgeRight) {
          //if element is too close to the right of the browser display on bottom left
          return {
            tooltipWidth,
            x: (x -= tooltipWidth - elementWidth),
            y: (y += elementHeight + gap),
          };
        }
        return {
          tooltipWidth,
          x,
          y: (y += elementHeight + gap),
        };
      }
      case 'left': {
        if (isCloseBrowserEdgeLeft) {
          //if element is too close to the left of the browser display on right
          return {
            tooltipWidth,
            x: (x += elementWidth + gap),
            y: (y += (elementHeight - tooltipHeight) / 2),
          };
        }
        return {
          tooltipWidth,
          x: (x -= tooltipWidth + gap),
          y: (y += (elementHeight - tooltipHeight) / 2),
        };
      }

      default: {
        return INITIAL_PLACEMENT;
      }
    }
  }
  return INITIAL_PLACEMENT;
}

/**
 * This helper returns the correct visibilitychange prop for the user's browser
 * https://developer.mozilla.org/en-US/docs/Web/API/Page_Visibility_API
 * */
export function getBrowserVisibilityProp() {
  // @ts-ignore
  // Opera 12.10 and Firefox 18 and later support
  if (typeof document.msHidden !== 'undefined') {
    return 'msvisibilitychange';
    // @ts-ignore
  } else if (typeof document.webkitHidden !== 'undefined') {
    return 'webkitvisibilitychange';
  }
  return 'visibilitychange';
}

/**
 * This helper returns the correct hidden prop for the user's browser
 * https://developer.mozilla.org/en-US/docs/Web/API/Page_Visibility_API
 * */
export function getBrowserDocumentHiddenProp() {
  // @ts-ignore
  if (typeof document.msHidden !== 'undefined') {
    return 'msHidden'; // @ts-ignore
  } else if (typeof document.webkitHidden !== 'undefined') {
    return 'webkitHidden';
  }
  return 'hidden';
}

export function getIsDocumentHidden() {
  const browserDocumentHiddenProp = getBrowserDocumentHiddenProp();

  // @ts-ignore
  return !!document[browserDocumentHiddenProp];
}

export function enablePageScroll() {
  document.body.style.overflow = 'unset';
}

export function disablePageScroll() {
  document.body.style.overflow = 'hidden';
}
