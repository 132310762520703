import { addMinutes, parseISO, isBefore } from 'date-fns';
import { v4 } from 'uuid';
import { BroadcastChannel } from 'broadcast-channel';

import {
  ActivityTrackerClientArgs,
  ActivityTrackerQueuedObject,
  Data,
} from './types';
import {
  RequestType,
  event as activityTrackerEvent,
  session as activityTrackerSession,
  requestTypes as activityTrackerRequestTypes,
  EventTypes,
  ReferralEventType,
  UtmTags,
  localStorageSessionTimestampKey,
  sessionStorageSessionIdKey,
  newSessionBroadcastChannelName,
  newSessionBroadcastMessage,
  referralEvent,
} from './constants';
import {
  getPersistentId,
  getCurrentSession,
  getPlatform,
  sendData,
  startNewSession,
} from './helpers';

import { setLocalStorageItem, setSessionStorageItem } from '@/modules/storage';
import { AxiosCompatibleResponse } from '@/modules/http/types';
import { getLogger } from '@/modules/observability/logging';
import { CustomEvents } from '@/constants/customEvents';

export interface IActivityTrackerClient {
  initialiseActivityTrackerSession: (
    experiments: string[],
    currentUserId?: number
  ) => Promise<{
    sessionId: string;
    isNewSession: boolean;
    cleanup: () => void;
  }>;
  reinitialiseATSession: (
    experiments: string[],
    currentUserId?: number
  ) => Promise<AxiosCompatibleResponse | void>;
  sendActivityTrackerEvent: (args: {
    event: EventTypes;
    transitionFrom: string;
    params: Record<string, unknown>;
    callback?: (data: Data) => void;
  }) => Promise<AxiosCompatibleResponse | void>;
  sendReferralEvent: (args: {
    event: ReferralEventType;
    userId?: number;
    platform: string;
    campaignUtmTags?: UtmTags;
  }) => Promise<AxiosCompatibleResponse | void>;
}

// New session will be generated after this time elapses
const SESSION_AGE_MINS = 30;

export class ActivityTrackerClient implements IActivityTrackerClient {
  private userId?: number;
  private static instance: ActivityTrackerClient;
  public initCalled = false;

  constructor(args: ActivityTrackerClientArgs) {
    this.userId = Array.isArray(args.userId) ? args.userId[0] : args.userId;
  }

  public static getInstance(
    args: ActivityTrackerClientArgs
  ): ActivityTrackerClient {
    if (
      !ActivityTrackerClient.instance ||
      ActivityTrackerClient.instance.userId !== args.userId
    ) {
      ActivityTrackerClient.instance = new ActivityTrackerClient(args);
    }

    return ActivityTrackerClient.instance;
  }

  private queueActivityTrackerEvent = (event: ActivityTrackerQueuedObject) => {
    if (window && window.DEPOP_AT_QUEUE) {
      window.DEPOP_AT_QUEUE.push(event);
    }
  };

  private sendActivityTrackerData = (
    requestType: RequestType,
    data: unknown,
    options?: Record<string, unknown>
  ) => {
    if (window.navigator.doNotTrack === '1') {
      return Promise.resolve();
    }
    return sendData(requestType, data, options);
  };

  private initialiseBroadcastChannelListener = () => {
    const channel = new BroadcastChannel(newSessionBroadcastChannelName);
    channel.onmessage = (msg) => {
      if (msg === newSessionBroadcastMessage) {
        const { localStorageSessionId, sessionStorageSessionId } =
          getCurrentSession();
        /**
         * If the session has been reset in another tab, the current tab will
         * be out of sync until a full pag refresh. This brings them in-sync.
         */
        if (
          localStorageSessionId &&
          sessionStorageSessionId !== localStorageSessionId
        ) {
          setSessionStorageItem(
            sessionStorageSessionIdKey,
            localStorageSessionId
          );
        }
      }
    };
    return { channel };
  };

  initialiseActivityTrackerSession = async (
    experiments: string[],
    currentUserId?: number
  ) => {
    this.initCalled = true;
    const { channel } = this.initialiseBroadcastChannelListener();

    function cleanup() {
      if (channel) {
        channel.close();
      }
    }

    const {
      sessionStorageSessionId,
      localStorageSessionId,
      localStorageSessionTimestamp,
    } = getCurrentSession();
    const persistentId = await getPersistentId();
    const platform = await getPlatform();

    // Start a new session if this is a brand new user or the session has expired
    if (
      !localStorageSessionId ||
      !localStorageSessionTimestamp ||
      isBefore(
        addMinutes(parseISO(localStorageSessionTimestamp), SESSION_AGE_MINS),
        new Date()
      )
    ) {
      const { sessionId } = startNewSession();
      // April '23 - Only send AT event if persistentId exists
      if (persistentId) {
        this.sendActivityTrackerData(activityTrackerRequestTypes.session, [
          activityTrackerSession(
            sessionId,
            persistentId,
            experiments,
            currentUserId,
            platform
          ),
        ]);
      }

      window.dispatchEvent(new Event(CustomEvents.SessionId));

      return {
        sessionId,
        isNewSession: true,
        cleanup,
      };
    }

    // extend the session
    const extendedTimestamp = new Date(Date.now()).toISOString();
    setLocalStorageItem(localStorageSessionTimestampKey, extendedTimestamp);
    if (!sessionStorageSessionId && persistentId) {
      setSessionStorageItem(sessionStorageSessionIdKey, localStorageSessionId);
      this.sendActivityTrackerData(activityTrackerRequestTypes.session, [
        activityTrackerSession(
          localStorageSessionId,
          persistentId,
          experiments,
          currentUserId,
          platform
        ),
      ]);

      window.dispatchEvent(new Event(CustomEvents.SessionId));

      return {
        sessionId: localStorageSessionId,
        isNewSession: false,
        cleanup,
      };
    }

    window.dispatchEvent(new Event(CustomEvents.SessionId));

    return {
      sessionId: sessionStorageSessionId as string,
      isNewSession: false,
      cleanup,
    };
  };

  reinitialiseATSession = async (
    experiments: string[],
    currentUserId?: number
  ) => {
    const { sessionStorageSessionId } = getCurrentSession();
    const persistentId = await getPersistentId();
    const platform = await getPlatform();
    let tracker;
    if (sessionStorageSessionId && persistentId) {
      tracker = this.sendActivityTrackerData(
        activityTrackerRequestTypes.session,
        [
          activityTrackerSession(
            sessionStorageSessionId,
            persistentId,
            experiments,
            currentUserId,
            platform
          ),
        ]
      );
    }
    return tracker ? tracker : Promise.reject('There was a problem');
  };

  sendActivityTrackerEvent = ({
    event,
    transitionFrom,
    params = {},
    baseEventOverrides = {},
    callback,
    options,
  }: {
    event: EventTypes;
    transitionFrom: string;
    params?: Record<string, unknown>;
    baseEventOverrides?: Record<string, unknown>;
    callback?: (data: Data) => void;
    options?: {
      handleRequestFailure: (eventData: Record<string, unknown>) => void;
    };
  }):
    | Promise<void>
    | Promise<AxiosCompatibleResponse<Record<string, unknown>>> => {
    const { sessionStorageSessionId } = getCurrentSession();
    // we should have one of these but if we don't for some reason, let's log it and see if we can work out why.
    getPersistentId().then((persistentIdentifier) => {
      getPlatform().then((platform) => {
        if (!persistentIdentifier) {
          // Sentry.captureMessage(
          //   `PersistentId not set. Prevented ${event} from being sent.`,
          //   'warning'
          // );
        }
        if (sessionStorageSessionId && persistentIdentifier) {
          try {
            const data = activityTrackerEvent(
              event,
              transitionFrom,
              sessionStorageSessionId,
              platform,
              persistentIdentifier,
              this.userId,
              params,
              baseEventOverrides
            );
            this.sendActivityTrackerData(RequestType.event, data)
              .then(() => {
                if (callback) {
                  callback(data);
                }
              })
              .catch(async (e) => {
                // If server responded with a non 2xx status code - log the error
                if (e.response) {
                  const err = e.response.data;
                  // This prevents reporting on baseEvent times being in the future
                  const shouldReport = !err?.includes('future');

                  if (shouldReport) {
                    getLogger().error(
                      `Activity tracker request responded with ${e.response?.status}`,
                      {
                        extra: {
                          eventType: data.baseEvent.eventType,
                          requestType: RequestType.event,
                          payload: data,
                          err,
                        },
                      }
                    );
                  }
                }
                // If request was made but no response received we fail silently
                if (e.request) {
                  // Fail silently
                }
                if (options?.handleRequestFailure) {
                  options.handleRequestFailure(data);
                }
              });
          } catch (error) {
            getLogger().error(`Activity tracker error`, { error });
          }
        } else {
          //If there's no sessionId then we push the events into a queue ready to be sent
          //This can happen if we try to send events before AT is properly initialised
          const eventObject = {
            event,
            transitionFrom,
            params,
            baseEventOverrides,
          };
          this.queueActivityTrackerEvent(eventObject);
        }
      });
    });
    return Promise.resolve();
  };

  sendReferralEvent = async ({
    event,
    userId,
    url,
    urlParameters,
    referer,
    productSlug,
    campaignUtmTags,
  }: {
    event: ReferralEventType;
    userId?: number;
    url?: string;
    urlParameters?: Record<string, string>;
    referer?: string;
    productSlug?: string;
    campaignUtmTags?: UtmTags;
  }) => {
    const persistentId = await getPersistentId();
    const { sessionStorageSessionId } = getCurrentSession();
    const eventTime = Math.floor(Date.now() / 1000);

    return this.sendActivityTrackerData(
      RequestType.referral,
      referralEvent(
        v4(),
        event,
        eventTime,
        sessionStorageSessionId as string,
        persistentId as string,
        userId?.toString(),
        'web',
        url,
        urlParameters,
        referer,
        productSlug,
        campaignUtmTags
      ),
      { includeAuth: Boolean(userId) }
    );
  };
}

export class MockActivityTrackerClient {
  initialiseActivityTrackerSession = jest.fn();
  reinitialiseATSession = jest.fn();
  sendActivityTrackerEvent = jest.fn();
  sendReferralEvent = jest.fn();
}
