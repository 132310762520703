import { currencyCodes, CurrencyCode } from './constants';

export function getCurrencySymbolFromCountry(country?: string) {
  if (country === 'au') {
    return 'A$';
  }
  if (
    country === 'de' ||
    country === 'fr' ||
    country === 'it' ||
    country === 'ie'
  ) {
    return '€';
  }
  if (country === 'us') {
    return 'US$';
  }

  if (country === 'ca') {
    return 'CA$';
  }
  if (country === 'nz') {
    return 'NZ$';
  }
  return '£';
}

export function getCurrencySymbolFromCurrencyCode(currencyCode?: CurrencyCode) {
  switch (currencyCode) {
    case CurrencyCode.AUD: {
      return 'A$';
    }
    case CurrencyCode.EUR: {
      return '€';
    }
    case CurrencyCode.USD: {
      return 'US$';
    }
    case CurrencyCode.BRL: {
      return 'R$';
    }
    case CurrencyCode.CAD: {
      return 'CA$';
    }
    case CurrencyCode.CZK: {
      return 'CZK';
    }
    case CurrencyCode.DKK: {
      return 'DKK';
    }
    case CurrencyCode.HKD: {
      return 'HK$';
    }
    case CurrencyCode.HUF: {
      return 'HUF';
    }
    case CurrencyCode.ILS: {
      return '₪';
    }
    case CurrencyCode.JPY: {
      return 'JP¥';
    }
    case CurrencyCode.MYR: {
      return 'MYR';
    }
    case CurrencyCode.MXN: {
      return 'MX$';
    }
    case CurrencyCode.TWD: {
      return 'NT$';
    }
    case CurrencyCode.NZD: {
      return 'NZ$';
    }
    case CurrencyCode.NOK: {
      return 'NOK';
    }
    case CurrencyCode.PHP: {
      return 'PHP';
    }
    case CurrencyCode.PLN: {
      return 'PLN';
    }
    case CurrencyCode.RUB: {
      return 'RUB';
    }
    case CurrencyCode.SGD: {
      return 'SGD';
    }
    case CurrencyCode.SEK: {
      return 'SEK';
    }
    case CurrencyCode.CHF: {
      return 'CHF';
    }
    case CurrencyCode.THB: {
      return 'THB';
    }
    case CurrencyCode.TRY: {
      return 'TRY';
    }
    default: {
      return '£';
    }
  }
}

export function getCurrencyCodeFromCountryCode(countryCode: string) {
  const capitalisedCountryCode = countryCode.toUpperCase();
  if (!currencyCodes[capitalisedCountryCode]) {
    return CurrencyCode.GBP;
  }
  return currencyCodes[capitalisedCountryCode];
}
