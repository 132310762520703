export const ZENDESK_HOME = 'https://depophelp.zendesk.com/hc/en-gb';

// Please set up a synthetic test if you add a new Zendesk link! Each test can only make requests to a max of 10 urls
// These tests let us know if the zendesk link breaks i.e. if the link changes
// https://app.datadoghq.com/synthetics/details/5yw-rua-w5q?from_ts=1739880552761&to_ts=1740485352761&live=true
// https://app.datadoghq.com/synthetics/details/qqr-ze6-dni?from_ts=1739880554413&to_ts=1740485354413&live=true
// https://app.datadoghq.com/synthetics/details/pnk-kcv-4ny?from_ts=1739880556130&to_ts=1740485356130&live=true

export const ZENDESK_ARTICLES = {
  BOOSTED_LISTINGS: {
    INFO: 'https://depophelp.zendesk.com/hc/en-gb/articles/10110299060753-What-are-Boosted-Listings-and-how-can-I-manage-them',
    TERMS_AND_CONDITIONS:
      'https://depophelp.zendesk.com/hc/en-gb/articles/9253129255569-Boosted-Listings-Policy',
    STATS:
      'https://depophelp.zendesk.com/hc/en-gb/articles/10110299060753-What-are-Boosted-Listing-stats-and-how-do-they-work-',
  },
  BULK_LISTING: 'https://depophelp.zendesk.com/hc/en-gb/articles/8608273715217',
  BUYING_CATEGORY:
    'https://depophelp.zendesk.com/hc/en-gb/categories/360000147707-Buying',
  BUYER_PROTECTION:
    'https://depophelp.zendesk.com/hc/en-gb/articles/360038461713-Depop-Protection-for-buyers',
  COOKIES_AND_SIMILAR_TECHNOLOGIES_POLICY:
    'https://depophelp.zendesk.com/hc/en-gb/articles/360024171514',
  DO_I_NEED_PAYPAL:
    'https://depophelp.zendesk.com/hc/en-gb/articles/360001772668-Why-do-I-need-PayPal',
  HOW_TO_USE_SALES_DOWNLOAD:
    'https://depophelp.zendesk.com/hc/en-gb/articles/360039263713',
  ITEM_NOT_AS_DESCRIBED:
    'https://depophelp.zendesk.com/hc/en-gb/articles/360001791507-My-item-is-not-as-described',
  PRIVACY_POLICY:
    'https://depophelp.zendesk.com/hc/en-gb/articles/360001792147-Privacy-Policy',
  REPOP: 'https://depophelp.zendesk.com/hc/en-gb/articles/12803766422289',
  SALES_TAX_FIND_OUT_MORE:
    'https://depophelp.zendesk.com/hc/en-gb/articles/18276924278033-Will-I-have-to-pay-for-tax-or-customs-on-my-order#will-i-have-to-pay-for-tax-or-customs-on-my-order--0-0',
  SELLERS_RECEIPTS: 'https://depophelp.zendesk.com/hc/en-gb',
  SHOP_STATS: 'https://depophelp.zendesk.com/hc/en-gb/articles/360019016817',
  STATE_SALES_TAX:
    'https://depophelp.zendesk.com/hc/en-gb/articles/360012516318',
  TERMS_OF_SERVICE:
    'https://depophelp.zendesk.com/hc/en-gb/articles/360001773148-Terms-of-Service',
  TWO_FACTOR_AUTH_FAQS:
    'https://depophelp.zendesk.com/hc/en-gb/articles/360017003737-Two-Factor-Authentication-Troubleshooting',
  CONTACT_US: `${ZENDESK_HOME}/requests/new?ticket_form_id=6804528236049`,
};

export const FOOTER_LINKS = {
  ABOUT_LINK: 'https://news.depop.com/who-we-are/about/',
  CAREERS_LINK: 'https://depopcareers.com/',
  BLOG_LINK: '/blog/',
  AMPLIFIED_LINK: 'https://amplified.depop-creators.com/connect#welcome',
  NEWS_LINK: 'https://news.depop.com',
  SUPPORT_LINK: ZENDESK_HOME,
  SAFETY_LINK: 'https://safety.depop.com',
};

export const SOCIAL_MEDIA_SITES = {
  FACEBOOK: 'https://facebook.com/depop',
  INSTAGRAM: 'https://instagram.com/depop/',
  TIKTOK: 'https://www.tiktok.com/@depop',
  TWITTER: 'https://twitter.com/askdepop/',
};
